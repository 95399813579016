<template>
    <v-card flat>
        <v-card-text class="pb-0 mb-0">
            <v-row style="border-bottom: 2px solid #4E87DD;">
                <v-col style="color:#4E87DD; font: normal normal bold 18px/25px Apple SD Gothic Neo;">결제카드 등록</v-col>
                <v-col style="color:#555555; font: normal normal 600 16px/25px Apple SD Gothic Neo;" class="text-right">매월 결제될 카드를 등록해주세요.</v-col>
            </v-row>
        </v-card-text>
        <v-card-text class="pt-0">
            <v-row class="mt-10">
                <v-col class="text-center" v-if="registered" style="font: normal normal bold 25px/25px Apple SD Gothic Neo">
                    {{ joinFormStep04.card_name }} ({{ joinFormStep04.card_num }})
                    <v-btn class="ml-16" color="grey" dark elevation="0" style="font: normal normal 600 16px/25px Apple SD Gothic Neo; margin-top: 2px;" @click="registCard">결제카드 변경</v-btn>
                </v-col>
                <v-col class="text-center" v-else>
                    <v-btn color="#4E87DD" dark elevation="0" style="font: normal normal 600 16px/25px Apple SD Gothic Neo; margin-top: 2px;" @click="registCard">결제카드 등록하기</v-btn>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-text class="mt-8 mb-n10">
            <v-row>
                <v-col>
                    <v-icon x-large class="mr-3">mdi-message-alert</v-icon><span style="font: normal normal bold 21px/25px Apple SD Gothic Neo;">결제등록 안내</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-alert
                        outlined
                        text
                        color="primary"
                        class="mt-n3"
                    >
                        <div class="ml-5 mt-1" style="font: normal normal normal 16px/25px Apple SD Gothic Neo;">- 서비스 이용료는 <span style="color: #FF0000">매월 1일</span>에 전월 이용한 요금이 자동 결제됩니다. </div>
                        <div class="ml-5 mt-1" style="font: normal normal normal 16px/25px Apple SD Gothic Neo;">- 첫달 이용요금은 말일까지 <span style="color: #FF0000">일할 계산</span>되어 다음달에 청구됩니다. </div>
                        <div class="ml-5 mt-1" style="font: normal normal normal 16px/25px Apple SD Gothic Neo;">- 결제카드 등록을 위해 <span style="color: #FF0000">공동인증서(구 공인인증서)</span>가 필요합니다. </div>
                        <div class="ml-5 mt-1" style="font: normal normal normal 16px/25px Apple SD Gothic Neo;">- 콜리 추가/삭제는 <span style="color: #FF0000">메인&gt;콜리설정</span>을 통해 관리할 수 있습니다. </div>
                        <div class="ml-5 mt-1" style="font: normal normal normal 16px/25px Apple SD Gothic Neo;">- 매월 1일 정기결제 실패 시 <span style="color: #FF0000">3일간의 유예기간</span>이 제공되며, 유예기간내 미결제 시 <span style="color: #FF0000">서비스가 정지</span>되어 전화의 수신/발신이 모두 불가합니다. </div>
                        <div class="ml-5 mt-1" style="font: normal normal normal 16px/25px Apple SD Gothic Neo;">- 이외 문의는 고객센터(070-4512-0000) 또는 arshelp@callgate.com 으로 문의주시기 바랍니다. </div>
                    </v-alert>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text class="text-center mt-10">
            <v-btn color="grey" width="200px" height="55px" dark elevation="0" style="font: normal normal bold 20px/37px Apple SD Gothic Neo; margin-right:5px;" @click="goPrev">이전</v-btn>
            <v-btn color="#4E87DD" width="200px" height="55px" dark elevation="0" style="font: normal normal bold 20px/37px Apple SD Gothic Neo; opacity: 1" @click="doNext" v-if="registered">가입요청</v-btn>
            <v-btn color="#4E87DD" width="200px" height="55px" dark elevation="0" style="font: normal normal bold 20px/37px Apple SD Gothic Neo; opacity: 0.5" @click="doNext" v-else>가입요청</v-btn>
        </v-card-text>
        <v-dialog v-model="modal" style="height: 60px;" v-show="false">
            <v-flex v-if="dialogMode === 'INICIS'" v-show="false">
                <tile-inicis-bill-key ref="tileInicis"></tile-inicis-bill-key>
            </v-flex>
        </v-dialog>
    </v-card>
</template>
<script>

import { mapGetters, mapState } from "vuex";
import joinApi from "@/api/join";

export default {
    name: 'join_step_04',
    components: {
        'tileInicisBillKey': () => import('@/components/join/tile_inicis_bill_key')
    },
    data: () => ({
        modal: false,
        dialogMode: ''
    }),
    computed: {
        ...mapGetters({
            joinTid: 'join/getJoinTid',
            joinFormStep03: 'join/getJoinFormStep03',
            joinFormStep04: 'join/getJoinFormStep04',
            product: 'join/getProduct'
        }),
        registered() {
            return this.joinFormStep04 && Object.keys(this.joinFormStep04).length != 0 && this.joinFormStep04.card_name && this.joinFormStep04.card_num
        },
    },
    created() {
    },
    mounted() {
        this.$store.commit('join/setJoinStep', 4)

        let paramJoinTid = this.$route.params.join_tid

        if (!this.joinFormStep03 || Object.keys(this.joinFormStep03).length == 0 || !this.joinTid || this.joinTid != paramJoinTid) {
            this.$store.dispatch('openAlert', {
                'message': '잘못된 접근입니다',
                closeCb: (va) => {
                    va.$router.push('/join/step01')
                },
                va: this
            })
        }
    },
    methods: {
        goPrev() {
            this.$router.push(`/join/step03/${this.joinTid}`)

            /*this.$store.dispatch('openConfirm', {
                'message': '이전 페이지로 돌아가시겠습니까?',
                'okCb': (va) => {
                    va.$router.push(`/join/step03/${va.joinTid}`)
                }, va: this
            })*/
        },
        registCard() {
            if (this.dialogMode == 'INICIS') {
                this.$refs.tileInicis.redo()
            } else {
                this.modal = true
                this.dialogMode = 'INICIS'
            }
        },
        doNext() {
            if (!this.registered) {
                this.$store.dispatch('openAlert', {
                    'message': '결제카드를 등록해주세요.',
                })
                return
            }

            let ctx = this

            this.$store.dispatch('openConfirm', {
                'message': '회원가입을 요청합니다.<br /><br />요청 후에는 정보수정이 불가합니다.',
                'acceptText': '가입요청',
                'cancelText': '취소',
                'okCb': (va) => {
                    joinApi.submitJoin({
                        'join_tid': ctx.joinTid
                    }, (va) => {
                        va.$router.push('/join/step06')
                    }, (err, va) => {
                        va.$store.dispatch('openAlert', {
                            'message': '처리 중 오류가 발생하였습니다.',
                            'sub_message': err,
                            'va': va
                        })
                    }, this)
                }
            })

        }
    }
}
</script>
<style>
</style>


